<template>
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="sc-x3cipg-0 iTJXYR"
  >
    <path
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
      stroke-width="0.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
  <h4>Waiting to connect</h4>
</template>

<style scoped>
svg {
  display: block;
  width: 100px;
  animation: 2s linear 0s infinite normal none running fvtopB;
  margin: 0 auto;
  fill: transparent;
}
svg path {
  stroke: var(--primary-color);
}
h4 {
  font-size: 18px;
  text-align: center;
}
@keyframes fvtopB {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
